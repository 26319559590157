import React from 'react'
  import { MDXTag } from '@mdx-js/tag'


  import DefaultLayout from "/buddy/openbook-github-io/workspaces/packages/gatsby-theme-andybrace/src/components/Layout/index.js";
import { Heading, ProjectsContainer, Grid, GridCol, ReadMore, Section, SkillList } from '../components';
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = DefaultLayout;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" Layout={this.layout} layoutProps={Object.assign({}, layoutProps, props)} components={components}>


      <Section>
    <Heading tag='h2' className='subheading h5' text='Recent projects' />
    <ProjectsContainer number={2} />
    <ReadMore path='/projects' />
      </Section>
      <Section>
    <Heading tag='h2' className='subheading h5' text='What I do' />
    <SkillList />
    <ReadMore path='/about' />
      </Section>
           </MDXTag>;
  }

}
MDXContent.isMDXComponent = true;
export const _frontmatter = {};
      